import * as moment from "moment/moment";
import {mapGetters} from "vuex";

export default {
    props:['list', 'itemId'],
    components:{
        Table: ()=> import('@/components/table/list/index.vue'),
    },
    data(){
        return{
            historyList: null,
            fields:[
                {
                    key:'id',
                    label:'id',
                },
                {
                    key:'title',
                    label:'name',
                },
                {
                    key:'login',
                    label:'login',
                },
                {
                    key:'operation',
                    label:'operation',
                },
                {
                    key:'updated_at',
                    label:'updated_at',
                },
                {
                    key:'userIp',
                    label:'ip',
                },
                {
                    key:'device',
                    label:'device',
                },
            ],
            items:[],
            changesLIst:null,
            changesType:{
                'customer_name':'Имя клиента',
                'email':'Email',
                'phone':'Телефон',
                'comment':'Коментарий',
                'address':'Адресс',
                'order_status_id':'Cтатус заказа',
                'shipment_method_id':'Способ доставки',
                'department_id':'Одел',
                'ip':'ip',
                'updated_at':'Время изменения',
                "payment_method_id":"Способ оплаты",
                "shipment_date":"Дата доставки",
                "employee_id":"employee_id",
                "order_id":"order_id",
                "completed":"Готово",
                "created_at":"timestamp without time zone",
                "shipment_data":"json",
                "status_open":"Статус открыто",
                "user_id_open":"Кем открыто",
                "id":"id",
                "site":"Сайт",
                "user_login_open":"character varying",
                "country":"Страна",
                "manager_comment":"Комментарий менеджера",
                "cancel_reason":"Причина отказа",
                "utm_source":"utm_source",
                "utm_medium":"utm_medium",
                "utm_term":"utm_term",
                "utm_content":"utm_content",
                "utm_campaign":"utm_campaign",
                "ttn":"ttn",
            }

        }
    },
    computed:{
        ...mapGetters({
            departments:'catalog/departments',
            shipmentMethods:'orders/shipmentMethods',
            orderStatusList:'orders/orderStatusList',
            paymentMethods:'orders/paymentMethods',
        })
    },
    created() {
        if(this.list.form.order_changes.length){
            this.changeHistoryList(this.list.form.order_changes);
        }
    },
    methods:{
        orderStatus(id){
            const itemType = this.orderStatusList.data.filter(item => item.id === id)[0]
            return itemType.title
        },
        shipmentMethod(id){
            const itemType = this.shipmentMethods.data.filter(item => item.id === id)[0]
            return itemType.title
        },
        department(id){
            const itemType = this.departments.data.filter(item => item.id === id)[0]
            return itemType.title
        },
        paymentMethod(id){
            const itemType = this.paymentMethods.data.filter(item => item.id === id)[0]
            return itemType.title
        },
        changeHistoryList(e){
            e.map(item=>{
                this.items.push(
                    {
                        id:item.id ? item.id : '',
                        title:item.member ? item.member : '',
                        login:item.login ? item.login : '',
                        operation:item.operation ? {operation:item.operation, id:item.id} : '',
                        updated_at:item.updated_at ? moment(item.updated_at).format('YYYY-MM-DD HH:mm') : '',
                        userIp:item.ip ? item.ip : '',
                        device:item.device ? item.device : '',
                    }
                )
            })
        },
        closePopup(){
            this.$emit('closePopup');
        },
        showWhatChanges(e){
            this.changesLIst = null;
            const choiseItem = this.list.form.order_changes.filter(item => item.id === e)[0]
            this.changesLIst = choiseItem.data
        }
    },
    destroyed() {
        this.historyList = null;
        this.changesLIst = null;
    }
}